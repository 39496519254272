import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useHttp } from '../../hooks/http.hook'
import useAuth from '../../hooks/useAuth.hook'
import config from '../../config.json'

const Login = () => {
    const { request } = useHttp()
    const auth = useAuth()
    const navigate = useNavigate()

    const [loginDate, setLoginDate] = React.useState({
        email: '',
        password: ''
    })

    React.useEffect(() => {
        if (!!auth.token)
            navigate("/");
    })
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await request(`${config.SERVER_URL}/api/auth/email/login`, 'POST', loginDate)
            auth.setUser(res.user)
            auth.setToken(res.token)
            navigate("/");
        } catch (error) {
            return false
        }

    }
    return (<div className='card' style={{maxWidth: '500px'}}>
        <form onSubmit={e => handleSubmit(e)} style={{padding: '1em'}}>
            <h2>Login</h2>

            <label className='form-group'>
                Login Name
                <input type={'text'} name="email" value={loginDate.email} onChange={e => setLoginDate({ ...loginDate, [e.target.name]: e.target.value })} />
            </label>

            <label className='form-group'>
                Password
                <input type={'password'} name="password" value={loginDate.password} onChange={e => setLoginDate({ ...loginDate, [e.target.name]: e.target.value })} />
            </label>

            <button className='btn btnSuccess'>Login</button>
        </form>
    </div>)
}

export default Login
import { Box, InputBase, InputLabel, MenuItem, Modal, Paper, Select } from '@mui/material'
import React, { useState, useRef } from 'react'
import { useHttp } from '../../hooks/http.hook'
import Preloader from '../components/Preloader'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from 'lodash.debounce'
import { getDomain, getHostname } from 'tldts';
import s from './domains.module.scss'


const Domains = () => {
    const [domains, setDomains] = React.useState(null)
    const [editMod, setEditMod] = React.useState(false) // edit
    // modal state
    const [open, setOpen] = React.useState(false);
    const [openDownload, setOpenDownload] = React.useState(false);
    // modal state end

    // serch link
    const [link, setLink] = React.useState('');
    // serch link end

    // domain fields
    const [integrationLink, setIntegrationLink] = useState('');
    const [domain, setDomain] = useState('');
    const [otherScripts, setOtherScripts] = useState('');
    // domain fields end
    const [nameFilter, setNameFilter] = React.useState('')

    const [template, setTemplate] = useState('');

    const [error, setError] = useState(null);

    const [openSelect, setOpenSelect] = React.useState(false);

    const [options, setOptions] = React.useState([]);

    const gtRef = useRef()

    const { request, loading } = useHttp()

    const handleCloseDownload = () => {
        setOpenDownload(false);
    }

    const handleClose = () => {
        setOpen(false);
        setIntegrationLink('')
        setDomain('')
        setOtherScripts('')
        setEditMod(false);
        setError('')
    }

    const openEditModal = (link, domain, otherScript) => {
        setOpen(true);
        setEditMod(true);
        console.log(otherScript)
        setIntegrationLink(link)
        setDomain(domain)
        setOtherScripts(otherScript)
    }

    const getDomains = React.useCallback(async () => {
        try {
            const res = await request('/api/main/domains')
            setDomains(res)

        } catch (error) {
            console.log(error.message)
        }
    }, [])

    const findLink = React.useCallback(debounce(async (search) => {
        try {
            const res = await request(`/api/main/find/link/by/${search}`)
            setOptions([...res])
        } catch (error) {
            console.log(error.message)
        }

    }, 1000), [])

    React.useEffect(() => {
        getDomains()
    }, [])

    React.useEffect(() => {
        findLink(link)
    }, [link, findLink])

    const submitHundler = async (e) => {
        e.preventDefault();
        const domainName = getHostname(domain);

        console.log(integrationLink)
        if (!domainName)
            return setError('incorrect domain')

        if (!integrationLink)
            return setError('incorrect integration link')

        try {
            const res = await request(`/api/main/domain/${editMod ? 'edit' : 'add'}`, 'POST', { domainName, integrationLink, otherScript: otherScripts })
            if (res?.message == 'success') {
                handleClose();
                getDomains();
            }
        } catch (error) {
            console.log(error.message)
        }


    }

    const addGT = () => {
        setOtherScripts(`${otherScripts}\n<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtRef?.current?.value}');</script>
<!-- End Google Tag Manager -->
<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${gtRef?.current?.value}"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->`)
    }

    const nameFilterHandler = (event) => {
        setNameFilter(event.target.value)
    }

    return (
        <div className='card'>
            <h1>Domains</h1>
            <div className='btn btnSuccess' onClick={e => setOpen(true)}>Add Domain</div>
            <Paper sx={{ p: '2px 4px', m: 2, display: 'flex', alignItems: 'center' }}>
                <InputBase
                    type="search"
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search By Name"
                    onChange={nameFilterHandler}
                />
            </Paper>
            <Paper className={s.template_wrap} sx={{ p: '2px 4px', m: 2, display: 'flex', alignItems: 'center' }}>
                {/* <a className={s.card} href="https://wa-traffic.com/static/template/tesler_v1.zip">
                    <div className={s.title}>
                        <span>Download</span>
                        <div>Tesler Template</div>
                    </div>
                    <div className={s.bg}></div>
                    <img src={require('../../images/domains/tesler_bg.png')} />
                </a> */}

                <a className={s.card} href="https://wa-traffic.com/static/template/tesler_juliya.zip">
                    <div className={s.title}>
                        <span>Download</span>
                        <div>Теслер-Юля</div>
                    </div>
                    <div className={s.bg}></div>
                    <img src={require('../../images/domains/tesler_bg.png')} />
                </a>

                <a className={s.card} href="https://wa-traffic.com/static/template/tesler-250.zip">
                    <div className={s.title}>
                        <span>Download</span>
                        <div>Теслер-250$</div>
                    </div>
                    <div className={s.bg}></div>
                    <img src={require('../../images/domains/tesler_bg.png')} />
                </a>

                <a className={s.card} href="https://wa-traffic.com/static/template/SiteT(p).zip">
                    <div className={s.title}>
                        <span>Tesler popup</span>
                        <div>(Канада + Юлин текст)</div>
                    </div>
                    <div className={s.bg}></div>
                    <img src={require('../../images/domains/tesler_bg.png')} />
                </a>

                <a className={s.card} href="https://wa-traffic.com/static/template/SiteB(p).zip">
                    <div className={s.title}>
                        <span>Теслер Black</span>
                        <div>(Канада)</div>
                    </div>
                    <div className={s.bg}></div>
                    <img src={require('../../images/domains/tesler_bg.png')} />
                </a>

                <a className={s.card} href="https://wa-traffic.com/static/template/Quantum_CU.zip">
                    <div className={s.title}>
                        <span>Quantum </span>
                        <div>(Канада)</div>
                    </div>
                    <div className={s.bg}></div>
                    <img src={require('../../images/domains/tesler_bg.png')} />
                </a>

                <a className={s.card} href="https://wa-traffic.com/static/template/Quantum_popup_CU.zip">
                    <div className={s.title}>
                        <span>Quantum popup</span>
                        <div>(Канада)</div>
                    </div>
                    <div className={s.bg}></div>
                    <img src={require('../../images/domains/tesler_bg.png')} />
                </a>

                <a className={s.card} href="https://wa-traffic.com/static/template/technik_neu.zip">
                    <div className={s.title}>
                        <span>TeslerDE (tilda)</span>
                        <div>(Германия)</div>
                    </div>
                    <div className={s.bg}></div>
                    <img src={require('../../images/domains/tesler_bg.png')} />
                </a>

                <a className={s.card} href="https://wa-traffic.com/static/template/quantumtemplate.zip">
                    <div className={s.title}>
                        <span>QuantumCA (tilda)</span>
                        <div>(Канада)</div>
                    </div>
                    <div className={s.bg}></div>
                    <img src={require('../../images/domains/tesler_bg.png')} />
                </a>

                <a className={s.card} href="https://wa-traffic.com/static/template/CoinBank_RO.zip">
                    <div className={s.title}>
                        <span>CoinBank RO</span>
                        <div>(Румыния)</div>
                    </div>
                    <div className={s.bg}></div>
                    <img src={require('../../images/domains/tesler_bg.png')} />
                </a>

                <a className={s.card} href="https://wa-traffic.com/static/template/immediate_edge_1.zip">
                    <div className={s.title}>
                        <span>Imedaite edge 1</span>
                        <div></div>
                    </div>
                    <div className={s.bg}></div>
                    <img src={require('../../images/domains/tesler_bg.png')} />
                </a>

                <a className={s.card} href="https://wa-traffic.com/static/template/immediate_edge_2.zip">
                    <div className={s.title}>
                        <span>Imedaite edge 2</span>
                        <div></div>
                    </div>
                    <div className={s.bg}></div>
                    <img src={require('../../images/domains/tesler_bg.png')} />
                </a>
            </Paper>

            {domains ? <div className={s.table}>
                {domains.filter(el => el.name.includes(nameFilter)).map(domain => <div className={s.tableItem}>
                    <div>
                        <b>{domain?.name}</b>
                        <div>Tracker: <span style={{ color: "#31708f" }}>{domain?.Link?.Trackers[0]?.counter ?? 0}</span></div>
                        <div>Last Update: {new Date(domain?.Link?.Trackers[0]?.updatedAt).toLocaleTimeString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }) ?? '---'}</div>

                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                        <div style={{ display: 'block', width: '100%' }}>{domain?.Link?.name} - {domain?.Link?.Offer?.name} - {domain?.Link?.Offer?.Partner?.name}</div>
                        <small style={{ display: 'block', color: '#00000099', width: '100%' }}>{domain?.Link?.linkStr}</small>
                    </div>
                    <a target={'_blank'} href={`https://${domain?.name}`} className='btn btnLink' style={{ alignSelf: "center" }}>View</a>
                    <div className='btn btnLink ' onClick={e => openEditModal(domain?.LinkId, domain?.name, domain?.sideScript)} style={{ alignSelf: "center" }}>Edit</div>
                    <div className='btn btnLink ' style={{ alignSelf: "center" }}>To Archive</div>

                </div>)}

            </div> : <Preloader />}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box className={s.templateModalWrap}>
                    <form className={s.templateForm} onSubmit={submitHundler}>
                        {error ? <div className='message message-danger'>
                            {error}
                        </div> : null}
                        <FormControl margin="normal" fullWidth>
                            <TextField label="Domain" variant="outlined" name='domain' value={domain} onChange={e => editMod ? null : setDomain(e.target.value)} />
                        </FormControl>
                        <FormControl margin="normal" fullWidth>
                            <Autocomplete
                                id="asynchronous-demo"
                                open={openSelect}
                                onOpen={() => {
                                    setOpenSelect(true);
                                }}
                                onClose={() => {
                                    setOpenSelect(false);
                                }}

                                onChange={(event, newValue) => {
                                    setIntegrationLink(newValue.id);
                                }}

                                isOptionEqualToValue={(option, value) => {
                                    return option.id
                                }}
                                getOptionLabel={(option) => {
                                    return `${option.name}  - ${option.Offer.name} - ${option.Offer.Partner.name} ${option.id}`
                                }}
                                renderOption={(props, option) => (
                                    <div {...props} style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                                        <div style={{ display: 'block', width: '100%' }}>{option.name} - {option.Offer.name} - {option.Offer.Partner.name}</div>
                                        <small style={{ display: 'block', color: '#00000099', width: '100%' }}>{option.id}</small>
                                    </div>
                                )}

                                options={options}
                                loadingSelect={loading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Integration Link"
                                        value={link}
                                        onChange={e => setLink(e.target.value)}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </FormControl>

                        <FormControl margin="normal" fullWidth>
                            Other script
                            <div style={{ margin: '4px 0', display: 'flex', gap: '4px' }}>
                                <input ref={gtRef} placeholder='GTM-XXXX' /><div className='btn btnWarning' onClick={addGT}>Add GTM</div>
                                <div className='btn btnDanger' style={{ marginLeft: 'auto' }} onClick={e => setOtherScripts('')}>Clear</div></div>
                            <textarea name={'otherScript'} value={otherScripts} onChange={e => setOtherScripts(e.target.value)} style={{ minHeight: '200px', padding: '8px' }} />
                        </FormControl>

                        {loading ? <div className='btn btnSecondary'>{(editMod ? 'Edit' : 'Add')} Domain</div> : <button className='btn btnSuccess' type='submit'>{(editMod ? 'Edit' : 'Add')} Domain</button>}
                    </form>
                </Box>
            </Modal>

        </div>
    )
}

export default Domains
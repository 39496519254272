import React, { useCallback } from 'react'
import { Link, useParams } from 'react-router-dom'
import Table from '../components/Table/Table'
import SimpleModal from '../components/SimpleModal/SimpleModal'
import { useHttp } from '../../hooks/http.hook'
import { AiFillDelete } from "react-icons/ai";
import { IconContext } from "react-icons";
import _ from 'lodash'
import config from '../../config.json'

const Offers = () => {
    const params = useParams()
    const { request } = useHttp()

    const [modal, setModal] = React.useState({
        addModal: false,
        removeModal: false
    })

    const [newOffer, setNewOffer] = React.useState({
        name: "",
        flow_hash: "",
        partnerId: params.id
    })

    const [removeId, setRemoveId] = React.useState(0)

    const [offers, setOffers] = React.useState([])
    // const removeOffer = (id) => {
    //     console.log(id)
    //     setModal({ ...modal, removeModal: true })
    // }

    const removeHandler = async () => {
        if (!!!removeId) setModal({ ...modal, removeModal: false })
        const res = await request(`${config.SERVER_URL}/api/main/remove/offer?id=${removeId}`)

        if (!res) {
            setRemoveId(0);
            setModal({ ...modal, removeModal: false })
            return;
        }
        _.remove(offers, offer => offer.id === res.id);
        setOffers(offers)
        setModal({ ...modal, removeModal: false })
    }

    const getOffers = async (e) => {
        const res = await request(`${config.SERVER_URL}/api/main/get/offer?id=${params.id}`)
        const newO = res.offers.map(el => {
            el['view/create'] = <Link className='btn btnLink' to={`/offers/${el.id}/add`}>View/Create link</Link>
            el.remove = 
            <div className='btn' onClick={e => { setRemoveId(el.id); setModal({ ...modal, removeModal: true }) }}><IconContext.Provider value={{ color: 'red', size: '1.5em' }}><AiFillDelete /></IconContext.Provider></div>
            return el
        })
        setOffers(newO)
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        if (newOffer.name.length > 2 && newOffer.flow_hash.length > 1 && !!newOffer.partnerId) {
            const res = await request(`${config.SERVER_URL}/api/main/add/offer`, 'POST', newOffer)
            const newState = offers
            newState.push(res.offers)
            const newO = newState.map(el => {
                el['view/create'] = <Link className='btn btnLink' to={`/offers/${el.id}/view`}>View/Create link</Link>
                el.remove = <div className='btn'><IconContext.Provider value={{ color: 'red', size: '1.5em' }}><AiFillDelete /></IconContext.Provider></div>
                return el
            })
            setOffers(newO)
            setModal({ ...modal, addModal: false })
        }
    }

    React.useEffect(() => {
        getOffers()
    }, [params.id])

    return <div>
        <div className='card'>
            <h2>Offers {params.id}</h2>
            <Link className='btn btnDanger' to={-1}>Back</Link>
            <div className='btn btnSuccess' onClick={e => setModal({ ...modal, addModal: true })}>Add Offer</div>
            {!!offers.length && <Table data={offers} />}
        </div>

        {modal.addModal && <SimpleModal modalName='addModal' closeSetter={setModal}>
            <form onSubmit={e => submitHandler(e)}>
                <legend>Add New Offer</legend>
                <label className='form-group'>
                    Name:
                    <input name='name' value={newOffer.name} onChange={e => setNewOffer({ ...newOffer, [e.target.name]: e.target.value })} />
                </label>

                <label className='form-group'>
                    Flow Hash:
                    <input name='flow_hash' value={newOffer.flow_hash} onChange={e => setNewOffer({ ...newOffer, [e.target.name]: e.target.value })} />
                </label>

                <button className='btn btnSuccess' type='submit'>Add</button>
            </form>
        </SimpleModal>}

        {modal.removeModal && !!removeId && <SimpleModal modalName='removeModal' closeSetter={setModal}>
            <legend>Remove Offer #{removeId}</legend>
            <div className='btn btnSuccess' onClick={e => {
                removeHandler()
            }}>Yes</div>
            <div className='btn btnDanger' onClick={e => {
                setRemoveId(0);
                setModal({ ...modal, removeModal: false })
            }}>No</div>
        </SimpleModal>}
    </div>
}

export default Offers
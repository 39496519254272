import React from 'react'
import { Link, Outlet } from 'react-router-dom'
const Tutorial = ({auth }) => {
    return <>
        <h2>Tutorials</h2>
        <nav>
                <Link className='btn btnLink' to="affiliate-integration">Интеграция с СРМ</Link>
        </nav>
        <Outlet />
    </>
}

export default Tutorial
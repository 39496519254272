import React from 'react'
import { Link, NavLink, Outlet } from 'react-router-dom'
import { useHttp } from '../../hooks/http.hook'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';


const Users = () => {
    const [users, setUsers] = React.useState([])
    const [offers, setOffers] = React.useState([])

    const { request } = useHttp()

    const getUsersList = async () => {
        const res = await request(`/api/main/users`)
        setUsers(res.users)
    }

    const setUserhandle = React.useCallback((users) => setUsers(users), [])
    const getOffers = async () => {
        const res = await request(`/api/main/get/offers`)
        setOffers(res.offers)
    }

    React.useEffect(() => {
        getUsersList()
        getOffers()
    }, [])

    return <div>
        <div className='card'>
            <h2>Users</h2>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>

                    <Grid item xs={3}>
                        <Grid container direction="column">
                            <NavLink
                                className={({ isActive }) =>
                                    isActive ? "btn btnPrimary linkLikeBtn" : "btn btnSuccess linkLikeBtn"
                                }
                                to={`create`}>Add</NavLink>
                            {!!users.length && !!offers.length && <>
                                {users.map(user => <NavLink
                                    className={({ isActive }) =>
                                        isActive ? "btn btnPrimary linkLikeBtn" : "btn btnLink linkLikeBtn"
                                    }
                                    to={`${user.id}`}>{user.email}</NavLink>)}
                            </>
                            }
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="center" alignItems="center" xs={9}>
                        <Outlet context={{ offers, getUsersList, setUserhandle }} />
                    </Grid>
                </Grid>
            </Box>
        </div>


    </div>
}

export default Users
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useHttp } from "../../hooks/http.hook";
import TableWrap from "../components/Table/TabelWrap";
import SimpleModal from '../components/SimpleModal/SimpleModal'
import tableCss from '../components/Table/style.module.scss'
import { flatten } from 'flat'
import { Stack, Box, Grid, Modal, NativeSelect, InputBase, Paper, TablePagination, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles';
import useAuth from "../../hooks/useAuth.hook";
import Preloader from '../components/Preloader'
import FontSizeSlider from "../components/FontSlider/FontSlider";
import NumberDetails from "../components/NumberDetails/NumberDetails";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';

import s from './s.module.scss'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Leads = () => {
    let query = useQuery();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [fontSize, setFontSize] = React.useState(parseInt(localStorage.getItem('tableFontSize')) || 14);
    const [leads, setLeads] = React.useState([])
    const [sortedUser, setSortedUser] = React.useState('')
    const [countryFilter, setCountryFilter] = React.useState('')
    const [partnerFilter, setPartnerFilter] = React.useState('')
    const [selectedIds, setSelectedIds] = React.useState([]);
    const [openOptions, setOpenOptions] = React.useState(false);
    const [partners, setPartners] = React.useState([])
    const auth = useAuth()
    const { request, loading } = useHttp()
    const [modal, setModal] = React.useState({
        modal: false
    })
    const [users, setUsers] = React.useState([])
    const [countries, setCountries] = React.useState([])
    const [details, setDetails] = React.useState(null)
    const [filteredLeads, setFilteredLeads] = React.useState([]);
    const [filteredLeadsCount, setFilteredLeadsCount] = React.useState(0);

    // Resend
    const [from, setFrom] = React.useState('00:00')
    const [to, setTo] = React.useState('23:59')
    const [changePartner, setChangePartner] = React.useState()


    const getLeads = async (e) => {
        const res = await request(`/api/main/get/leads`)
        setLeads(res.leads)
    }

    const startTableRef = React.useRef(null);

    const handleOpenResend = () => {
        // getCheckedLinks()
        setOpenOptions(true);
    }

    const handleCloseResend = () => {
        setOpenOptions(false);
    }

    const getConversionList = async () => {
        const data = await request(`/api/webhook/conversion/get`, 'POST')
        getLeads()
    }

    const getUsersList = async () => {
        const res = await request(`/api/main/users`)
        setUsers(res.users)
    }

    // const getCountries = async () => {
    //     const res = await request(`/api/main/get/country`)
    //     setCountries(res.country)
    // }

    const sortHandle = (event) => {
        setSortedUser(event.target.value)
        handleChangePage(null, 0)
    }

    const handleScrollToTableStart = () => {
        startTableRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const countryFilterHandler = (event) => {
        setCountryFilter(event.target.value)
    }

    const partnerFilterHandler = (event) => {
        setPartnerFilter(event.target.value)
    }

    React.useEffect(() => {
        getLeads()
        getUsersList()
        // getCountries()
    }, [])

    const headers = {
        '': '',
        'id': '',
        ['time']: '',
        ['first name']: '',
        ['last name']: '',
        email: '',
        phone: '',
        ['callcenter status']: '',
        status: '',
        ip: '',
        ['link name']: '',
        gone: '',
        ['details']: '',
        '': '',
    }

    const [nameFilter, setNameFilter] = React.useState(query.get("q") ? query.get("q") + ' ' : '')

    const nameFilterHandler = (event) => {
        setNameFilter(event.target.value)
    }

    const getPartners = async (e) => {
        const res = await request(`/api/main/get-partners`, 'GET', null, {
            ['x-access-token']: auth.token
        })
        setPartners(res.partners)
        setChangePartner(res.partners[0].endpoint_name)
    }

    const handleDelete = async (id) => {
        try {
            await request(`/api/main/remove/lead/${id}`, 'POSt', {}, { Authorization: `Bearer ${auth.token}` })
            setLeads(leads.filter(lead => lead.id !== id));
        } catch (error) {
            console.error(error);
        }
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        handleScrollToTableStart()
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    React.useEffect(() => {
        localStorage.setItem('tableFontSize', fontSize);
    }, [fontSize]);

    const filterLeads = () => {
        const filtered = leads.filter(lead => {
            lead.gone = lead.gone || '';
            if (!!sortedUser.length && lead['Link']['User']['id'] != sortedUser) {
                return false;
            }

            if (!lead?.Link?.offer_name.includes(nameFilter) && !lead.serachStr.includes(nameFilter)) {
                return false;
            }
            // if (!lead.serachStr.includes(nameFilter)) {
            //     return false;
            // }
            // if (!lead.Link.country.includes(countryFilter)) {
            //     return false;
            // }
            if (!lead.gone.includes(partnerFilter)) {
                return false;
            }
            return true;
        });
        setFilteredLeads(filtered);
        setFilteredLeadsCount(filtered.length);
    };

    // const filteredLeadsCount = () => {
    //     return leads
    //         .filter(lead => {
    //             lead.gone = lead.gone || ''

    //             console.log(lead.gone, lead.Link.gone)
    //             if (!!sortedUser.length && lead['Link']['User']['id'] != sortedUser) {
    //                 return false;
    //             }
    //             if (!lead.serachStr.includes(nameFilter)) {
    //                 return false;
    //             }
    //             if (!lead.Link.country.includes(countryFilter)) {
    //                 return false;
    //             }
    //             if (!lead.gone.includes(partnerFilter)) {
    //                 return false;
    //             }
    //             return true;
    //         }).length
    // }

    const handleCheckboxChange = React.useCallback((e, id) => {
        e.preventDefault();
        if (e.target.checked) {
            setSelectedIds([...selectedIds, ...leads.filter(el => el.id == id)]);
        } else {
            setSelectedIds(selectedIds.filter((selectedId) => selectedId.id !== id));
        }
    }, [selectedIds, setSelectedIds, leads]);



    const resendLeads = async () => {
        try {
            await request(`/api/main/resend/leads`, 'POST', { ids: selectedIds }, { Authorization: `Bearer ${auth.token}` })
            setSelectedIds([]);
            getLeads()
        } catch (error) {
            console.error(error);
        } finally {
            setOpenOptions(false)
        }
    }

    const changeTime = (e) => {
        const value = e.target.value
        const name = e.target.name

        if (name === 'from') {
            setFrom(value)
        }

        if (name === 'to') {
            setTo(value)
        }
    }

    const addResendOptions = () => {
        setSelectedIds(prev => {
            const newEl = prev.map(el => {

                el.send_time_from = from
                el.send_time_to = to
                el.action = changePartner || partners[0].endpoint_name
                return el
            })

            return newEl

        })
    }


    const resendDefault = () => {
        setSelectedIds(prev => {
            const newEl = prev.map(el => {
                el.send_time_from = '00:00'
                el.send_time_to = '23:59'
                el.action = ''

                return el
            })

            return newEl

        })
    }

    React.useEffect(() => {
        getPartners()
    }, [])

    React.useEffect(() => {
        filterLeads();
    }, [leads, sortedUser, nameFilter, countryFilter, partnerFilter, selectedIds]);

    return <>
        <div className="card" ref={startTableRef}>
            <h2>Leads</h2>
            <Link className='btn btnDanger' to="/">Back</Link>
            <Paper sx={{ p: '2px 4px', m: 2, display: 'flex', alignItems: 'center' }}>
                <InputBase
                    type="search"
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search By ID, Name, Last Name or Email"
                    onChange={nameFilterHandler}
                    value={nameFilter}
                />
            </Paper>
            {!!auth.user?.isAdmin && <Box sx={{ flexGrow: 1 }}>
                <Grid container gap={'10px'} marginTop={'16px'}>
                    <Item>
                        <button className={loading ? 'btn btnSecondary' : 'btn btnPrimary'} onClick={getConversionList} disabled={loading ? 'disabled' : ''}>Update Status</button>
                    </Item>
                    {!!users.length &&
                        <Item>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                Sort by: <div>
                                    <select onChange={sortHandle} className='btn btnPrimary'>
                                        <option value={''} >All</option>
                                        {users.map(user => <option value={user.id}>{user.email}</option>)}
                                    </select>
                                </div>
                            </div>
                        </Item>
                    }
                    {!!countries.length &&
                        <Item>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                Country: <div>
                                    <select onChange={countryFilterHandler} className='btn btnPrimary'>
                                        <option value={''} >All</option>
                                        {countries.map(country => <option value={country.country}>{country.country}</option>)}
                                    </select>
                                </div>
                            </div>
                        </Item>
                    }

                    {!!auth.user?.isAdmin && !!partners.length &&
                        <Item>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                Partner: <div>
                                    <select onChange={partnerFilterHandler} className='btn btnPrimary'>
                                        <option value={''} >All</option>
                                        {partners.map(partner => <option value={partner.name.toLowerCase()}>{partner.name}</option>)}
                                    </select>
                                </div>
                            </div>
                        </Item>
                    }
                    <Item>
                        <FontSizeSlider fontSize={fontSize} onChange={setFontSize} />
                    </Item>
                    <Item>
                        <button className={`btn ${selectedIds.length != 0 ? 'btnPrimary' : 'btnSecondary'}`} onClick={selectedIds.length != 0 && handleOpenResend}>Resend</button>
                    </Item>
                </Grid>
            </Box>}
            {!!leads.length ? <>
                <Grid container justifyContent={'flex-end'}>
                    <TablePagination
                        sx={{ width: "100%" }}
                        rowsPerPageOptions={[50, 100, 150, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={filteredLeadsCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: {
                                'aria-label': 'rows per page',
                            },
                            native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
                <TableWrap>
                    <div className={tableCss.table} style={{ fontSize }}>
                        <div className={tableCss.header} style={{ "gridTemplateColumns": `25px 40px ${70 + fontSize}px repeat(8,minmax(80px, 1fr)) ${90 + fontSize}px ${90 + fontSize}px 25px 25px` }}>
                            {Object.keys(headers).map(header => <div>
                                {header}
                            </div>)}
                        </div>
                        <div className={tableCss.content}>
                            {filteredLeads
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((lead, index) => <div key={lead.id} style={{ "gridTemplateColumns": `25px 40px ${70 + fontSize}px repeat(8,minmax(50px, 1fr)) ${90 + fontSize}px ${90 + fontSize}px 25px 25px`, padding: '8px 0' }}>
                                    <div onDoubleClick={() => handleDelete(lead.id)} style={{ color: 'red', cursor: 'pointer' }}>✕</div>
                                    <div>{page * rowsPerPage + index + 1}</div>
                                    <div>{new Date(lead['createdAt']).toLocaleString('uk-UA')}</div>
                                    <div>{lead['first_name']}</div>
                                    <div>{lead['last_name']}</div>
                                    <div>{lead['email']}</div>
                                    <div>{lead['phone'] && <NumberDetails phoneNumber={lead['phone']} />}</div>
                                    <div>{lead['result']}</div>
                                    <div className={
                                        lead['status'] === 'sent' ? 'message message-warning' :
                                            lead['status'] === 'approved' ? 'message message-success' : ''}
                                        style={{ fontSize, margin: 0 }}>{lead['status']}</div>
                                    <div>{lead['ip']}</div>
                                    <div>
                                        {lead['other_params']?.utm && <div style={{ color: '#337ab7' }}>{lead['other_params']?.utm}</div>}
                                        {lead['Link.offer_name'] || (lead?.Link?.offer_name ?? '')}
                                        <div style={{ fontSize: fontSize + 4 }}><sup style={{ padding: "1px 3px" }} className="message-success">{lead?.Link?.approvedLeadCount}</sup>/<sub style={{ padding: "1px 3px" }} className="message-warning">{lead['leadLinkCount']}</sub></div>
                                    </div>
                                    <div>{lead['gone'] || 'error'}</div>

                                    <div style={{ fontSize }} className="btn btnLink" onClick={e => {
                                        setModal({ ...modal, modal: true });
                                        setDetails(flatten(lead['other_params']))
                                    }}>Details</div>

                                    {!!auth.user?.isAdmin && lead.ref &&
                                        <input
                                            style={{ cursor: 'pointer' }}
                                            type="checkbox"
                                            checked={selectedIds.map(el => el.id).includes(lead.id)}
                                            onChange={(e) => handleCheckboxChange(e, lead.id)}
                                        />}

                                </div>)}
                        </div>
                    </div>
                </TableWrap>
                <TablePagination
                    sx={{ width: "100%" }}
                    rowsPerPageOptions={[50, 100, 150, { label: 'All', value: -1 }]}
                    colSpan={3}
                    count={filteredLeadsCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: {
                            'aria-label': 'rows per page',
                        },
                        native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </> : <Preloader />}
        </div >

        {
            modal.modal && details && <SimpleModal modalName={'modal'} closeSetter={setModal}>
                <div>Respons Details</div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                    {Object.keys(details).map(key => <div style={{ display: 'flex', textAlign: 'left', width: '100%', gap: '5px', overflowWrap: 'break-word' }}>
                        <div style={{ flex: '1 1 0', textAlign: 'right' }}><b>{key}:</b></div> <div style={{ flex: '2 1 0', maxWidth: '66%', textAlign: 'left' }}>{details[key]}</div>
                    </div>)}
                </div>
            </SimpleModal>
        }

        {/* Resend */}
        {
            !!auth.user?.isAdmin &&
            <Modal
                keepMounted
                className={s.sheduleModal}
                open={openOptions}
                onClose={handleCloseResend}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >

                <Stack sx={style} spacing={1} >
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseResend}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {!!partners.length && changePartner && <Paper>
                        <div className={s.timeWrapForAll}>
                            From <input type={'time'} name='from' value={from} onChange={changeTime} />
                            to <input onChange={changeTime} name='to' min={from} type={'time'} value={to} />

                            transfer to → <NativeSelect
                                defaultValue={partners[0].endpoint_name}
                                inputProps={{
                                    name: 'age',
                                    id: 'uncontrolled-native',
                                }}
                                onChange={e => setChangePartner(e.target.value)}
                            >
                                {partners.map(partner => <option value={partner.endpoint_name}>{partner.name}</option>)}
                            </NativeSelect>

                            <div className="btn btnSuccess" onClick={addResendOptions}>Set All</div>
                            <div className="btn btnDanger" title="Set Default Value For All" onClick={resendDefault}><DeleteForeverIcon /></div>

                        </div>
                    </Paper>}

                    <div className={s.transferModal}>
                        {selectedIds.map((el) => <HolderItem key={el.id} link={el} partners={partners} setSelectedIds={setSelectedIds} />)}
                    </div>
                    <div className={`btn ${loading ? 'btnSecondary' : 'btnSuccess'}`} onClick={loading ? null : resendLeads}>Save</div>

                </Stack>
            </Modal>
        }
    </>
}

const HolderItem = ({ link, partners, setSelectedIds }) => {
    const changeTime = (e) => {
        const value = e.target.value
        const name = e.target.name

        setSelectedIds(prev => {
            const newEl = prev.map(el => {
                if (link.id === el.id) {
                    if (name === 'from')
                        el.send_time_from = value

                    if (name === 'to')
                        el.send_time_to = value

                }
                return el

            })

            return newEl

        })
    }

    const setChangePartner = (e) => {
        const value = e.target.value

        setSelectedIds(prev => {
            const newEl = prev.map(el => {
                if (link.id === el.id) {
                    el.action = value
                }
                return el

            })

            return newEl

        })
    }

    const removeHolder = (linkId, id) => {
        console.log("index: ", id)
        console.log("linkId: ", linkId)

        setSelectedIds(prev => {
            const newEl = prev.map(el => {
                el.send_time_from = '00:00'
                el.send_time_to = '23:59'
                return el
            })

            return newEl

        })
    }

    return <Paper>
        <div className={s.transfer_card}>

            <div className={s.header}>
                <div>{link.first_name}</div>
                <div>{link.last_name}</div>
                <div>{link.email}</div>
                <div><NumberDetails phoneNumber={link['phone']} /></div>
                <div>{link.Link.offer_name}</div>
                <div>{link.gone}</div>
                <div className={`message `} style={{ margin: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={`${link.gone || (flatten(link?.other_params)?.partner ?? '')}`}>{link.gone || (flatten(link?.other_params)?.partner ?? '')}</div>
            </div>

            <div className={s.timeWrap}>
                From <input type={'time'} name='from' value={link.send_time_from || '00:00'} onChange={changeTime} />
                to <input onChange={changeTime} min={link.send_time_from || '00:00'} name='to' type={'time'} value={link.send_time_to || '23:59'} />
                transfer to → <NativeSelect
                    defaultValue={partners[0].endpoint_name}
                    value={link.action}
                    inputProps={{
                        name: 'age',
                        id: 'uncontrolled-native',
                    }}
                    onChange={setChangePartner}
                >
                    {partners.map(partner => <option value={partner.endpoint_name}>{partner.name}</option>)}
                </NativeSelect>
            </div>

            <div className={s.shedule}>
                {/* <Stack sx={{ width: '100%' }} spacing={1}>
                {link.Holders && link.Holders.map((el, i) => <Alert severity="warning" className="shedule-alert">
                    From <b>{el.holder_from}</b> to <b>{el.holder_to}</b>
                    <div className='close' onClick={() => {
                        removeHolder(link.id, el.id)
                    }}><CloseIcon /></div>
                </Alert>)}
            </Stack> */}
            </div>

        </div>
    </Paper>
}

export default Leads